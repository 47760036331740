import React, { useContext } from "react";
import { AppSettingContext } from "../providers/AppSettingsProvider";
import { fontSizeMultipleOfSet, widthMultipleOfSet } from "../config";

const useSize = () => {
  const setting = useContext(AppSettingContext);
  const { appSettings } = setting;

  const fontSize = (defaultSize = 16, unit = 'px') => {
    if (!defaultSize) {
      return { fontSize: `${defaultSize}${unit}` };
    }
    if (typeof defaultSize !== "number") {
      return { fontSize: `${defaultSize}${unit}` };
    }

    const fontSize = defaultSize * fontSizeMultipleOfSet[appSettings.fontSize];
    return { fontSize: `${fontSize}${unit}` };
  };

  const imgWidth = (defaultSize = 100, unit = 'px' ) => {
    if (!defaultSize) {
      return { width: `${defaultSize}${unit}` };
    }
    if (typeof defaultSize !== "number") {
      return { width: `${defaultSize}${unit}` };
    }

    const width = defaultSize * widthMultipleOfSet[appSettings.fontSize];

    return { width: `${width}${unit}` };
  };

  return [fontSize, imgWidth];
};

export default useSize;
