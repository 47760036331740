import { useEffect, useState } from "react";
import TickLocalModal from "../../components/Modal/TickLocalModal";
import TickTitleBar from "../../components/TickTitleBar/TickTitleBar";
import { Qrscan } from "../../components/Qrscan/Qrscan";
import attendance from "../../assets/images/Scannersquare_green_QRCODE.svg";
import departure from "../../assets/images/Scannersquare_red_QRCODE.svg";
import styles from "./ScanModal.module.scss";
import { useDispatch } from "react-redux";
import { showModal } from "../../redux/tickModalSlice";
import successSoundTrack from "../../assets/sounds/success_sound_track.mp3";
import failSoundTrack from "../../assets/sounds/fail_sound_track.mp3";
import { useTranslation } from "react-i18next";
import { eventAttendance } from "../../services/apis/eventApis";
import { educationAttendance } from "../../services/apis/educationApis";
import { APP } from "../../env";
import { cpdAttendance } from "../../services/apis/cpdApi";
import TickSelect from "../../components/TickSelect/TickSelect";
import { Howl } from "howler";

const ScanModal = (props) => {
  const {
    visible = false,
    setVisible = () => {},
    clearState = () => {},
    isAttendance,
    id,
    type,
    details = [],
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [scanValue, setScanValue] = useState(null);

  const qrCodeSource = isAttendance ? attendance : departure;
  const title = isAttendance ? t("t_attendance") : t("t_departure");
  const successAudio = new Audio(successSoundTrack);
  const successSound = new Howl({
    src: ["../../assets/sounds/success_sound_track.mp3"],
  });
  const failAudio = new Audio(failSoundTrack);
  const failSound = new Howl({
    src: ["../../assets/sounds/fail_sound_track.mp3"],
  });

  useEffect(() => {
    return () => {
      // clearState();
    };
  }, []);

  useEffect(() => {
    if (!scanValue) return;
    handleScan(scanValue);
  }, [scanValue]);

  const defineCase = (type) => {
    let pair = [];
    switch (type) {
      case "event":
        pair[0] = "eventType";
        pair[1] = "event";
        break;
      case "education":
        pair[0] = "educationCourseType";
        pair[1] = "educationClass";
        break;
      case "cpd":
        pair[0] = "authorityBody";
        pair[1] = "activityCode";
        break;
      default:
    }
    return pair;
  };

  const showIdCase = (type) => {
    let showId = false;
    switch (type) {
      case "education":
      case "cpd":
        showId = true;
        break;
      default:
    }
    return showId;
  };

  const handleScannedValueIsValid = (data) => {
    if (!isAttendance) return true;
    let isValid = false;
    if (type === "event" && data.eventId == id) {
      isValid = true;
    }
    if (type === "education" && data.educationClassId == id) {
      isValid = true;
    }
    if (type === "cpd") {
      // current flow no need check anything
      isValid = true;
    }
    return isValid;
  };

  const fetchApi = async (data) => {
    let attendType = isAttendance ? "in" : "out";
    let success = true;
    let message = [];

    if (type === "event") {
      await eventAttendance({
        eventEnrollmentId: data.eventEnrollmentId,
        contactId: data.contactId,
        attendType,
        addBy: APP.auth.userId,
      })
        .then((resp) => {
          if (!resp.success) {
            success = false;
            if (Array.isArray(resp.message)) {
              resp?.message?.forEach((element) => {
                message.push({ msg: element });
              });
            } else {
              message.push({ msg: resp?.message });
            }
          }
          console.log(resp, "Event::::::::::::::::::::::::");
        })
        .catch((e) => console.log(e));
    }
    if (type === "education") {
      await educationAttendance({
        educationClassEnrollmentId: data.educationClassEnrollmentId,
        contactId: data.contactId,
        attendType,
        addBy: APP.auth.userId,
      })
        .then((resp) => {
          if (!resp.success) {
            success = false;
            if (Array.isArray(resp.message)) {
              resp?.message?.forEach((element) => {
                message.push({ msg: element });
              });
            } else {
              message.push({ msg: resp?.message });
            }
          }
          console.log(resp, "Education::::::::::::::::::::::::");
        })
        .catch((e) => console.log(e));
    }
    if (type === "cpd") {
      await cpdAttendance({
        cpdActivityId: id,
        contactId: data.contactId,
        attendType,
        addBy: APP.auth.userId,
      })
        .then((resp) => {
          if (!resp.success) {
            success = false;
            if (Array.isArray(resp.message)) {
              resp?.message?.forEach((element) => {
                message.push({ msg: element });
              });
            } else {
              message.push({ msg: resp?.message });
            }
          }
          console.log(resp, "CPD::::::::::::::::::::::::");
        })
        .catch((e) => console.log(e));
    }
    if (success) {
      dispatch(
        showModal({
          key: "success",
          msg: "QRcode Scan Success",
        })
      );
      // successSound.play();
      successAudio.play();
    } else {
      dispatch(
        showModal({
          key: "warning",
          msg: message,
        })
      );
      // failSound.play();
      failAudio.play();
    }
  };

  const handleScan = (data) => {
    if (handleScannedValueIsValid(data)) {
      fetchApi(data);
    } else {
      dispatch(
        showModal({
          key: "warning",
          msg: [{ msg: "Invalid QR code" }],
        })
      );
      // failSound.play();
      failAudio.play();
    }
  };

  return (
    <TickLocalModal visible={visible}>
      <div style={{ height: "100vh", width: "100%", position: "relative" }}>
        <TickTitleBar
          title={title}
          onGoBack={() => setVisible(false)}
          theme={isAttendance && "green"}
          isCrossIcon={true}
        />
        {details.length > 0 && (
          <>
            <TickSelect
              disabled
              label={`t_${defineCase(type)[0]}`}
              type={defineCase(type)[0]}
              detail={details[0]}
            />
            <TickSelect
              disabled
              label={`t_${defineCase(type)[1]}`}
              type={defineCase(type)[1]}
              detail={details[1]}
              params={details[0]}
              showId={showIdCase(type)}
            />
          </>
        )}
        <div className={styles.qrContainer}>
          {visible ? <Qrscan setData={setScanValue} /> : null}
        </div>
        <div className={styles.qrIconContainer}>
          <img className={styles.qr} src={qrCodeSource} alt="qr" />
        </div>
      </div>
    </TickLocalModal>
  );
};

export default ScanModal;
