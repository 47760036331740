import { QrReader } from "react-qr-reader";
import { handleConvertFromJson } from "../../helpers/helpers";
import _ from "lodash";

export const Qrscan = (props) => {
  return (
    <QrReader
      scanDelay={1000}
      constraints={{ facingMode: "environment" }}
      onResult={(result, error) => {
        if (result) {
          props.setData((prev) => {
            if (
              JSON.stringify(prev) ===
              JSON.stringify(handleConvertFromJson(result?.text))
            ) {
              return prev;
            }
            return handleConvertFromJson(result?.text);
          });
        }
        if (error) {
          console.info(error);
        }
      }}
      containerStyle={{ width: "100vh", height: "100vh" }}
      videoStyle={{ width: "100vh", height: "100vh" }}
      videoContainerStyle={{ width: "100vh", height: "100vh" }}
      style={{ width: "100vh", height: "100vh" }}
    />
  );
};
