export const colors = {
  blue: '#1C63B7',
  green: '#00A650',
  white: '#eee',
  textWhite: '#FFFFFF',
  black: 'black',
  defaultBg: '#DBDBDB',
  textGrey: '#6B6B6B',
  yellow: '#F19100',
  modalTransparentBg: 'rgba(0,0,0,0.5)',
  loadingModalBg: '#00000080',
  grey: '#EFEFEF',
  lightGrey: '#D6D6D7',
  pureWhite: '#FFF',
  red: '#ED1212',
  themeRed: '#600',
};

export const fontSizes = {
  titleFont: 16,
  headerFont: 10,
  contentFont: 14,
  descFont: 10,
};

export const fontWeights = {
  normalWeight: '400',
  spacialWeight: '500',
  highlightWeight: '600',
  importantWeight: '700',
};
