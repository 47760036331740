import { useEffect } from "react";
import { IonBackdrop } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../redux/tickModalSlice";
import styles from "./modal.module.scss";

const TickGlobalModal = (props) => {
  const { children, autoClose = 0 } = props;
  const dispatch = useDispatch();
  const tickGlobalModalState = useSelector((state) => state.tickModal);
  
  useEffect(() => {
    if (tickGlobalModalState.show) {
      if (autoClose > 0) {
        setTimeout(() => {
          dispatch(closeModal());
        }, autoClose);
      } else if (tickGlobalModalState.params.autoClose > 0) {
        setTimeout(() => {
          dispatch(closeModal());
        }, tickGlobalModalState.params.autoClose);
      }
    }
  }, []);

  return (
    <>
      {/* <IonBackdrop
        visible={tickGlobalModalState.show}
        className={styles.tickBackdrop}
      ></IonBackdrop> */}
      <IonBackdrop visible={tickGlobalModalState.show}></IonBackdrop>
      {children}
    </>
  );
};

export default TickGlobalModal;
