import { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import TickTitleBar from "../../components/TickTitleBar/TickTitleBar";
import styles from "./Manual.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { Table, Button, Modal, Row, Col, Input } from "antd";
import { useTranslation } from "react-i18next";
import {
  eventAttendance,
  eventMemberListByPage,
} from "../../services/apis/eventApis";
import {
  educationAttendance,
  educationMemberListByPage,
} from "../../services/apis/educationApis";
import { cpdAttendance, cpdMemberListByPage } from "../../services/apis/cpdApi";
import { APP } from "../../env";
import { useDispatch } from "react-redux";
import { showModal } from "../../redux/tickModalSlice";

const Manual = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ["bottomCenter"],
  });
  const [memberNos, setMemberNos] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [record, setRecord] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const category = new URLSearchParams(location.search).get("category");
  const urlId = new URLSearchParams(location.search).get("id");
  const currentLanguage = i18n.language === "en";

  const fetchSourceApi =
    category === "event"
      ? eventMemberListByPage
      : category === "education"
      ? educationMemberListByPage
      : cpdMemberListByPage;

  const postApi =
    category === "event"
      ? eventAttendance
      : category === "education"
      ? educationAttendance
      : cpdAttendance;

  const idObj =
    category === "event"
      ? { eventId: urlId }
      : category === "education"
      ? { educationClassId: urlId }
      : { activityCodeId: urlId };

  useEffect(() => {
    if (!memberNos) {
      fetchSource(pagination);
    }
  }, [memberNos]);

  const handleRefresh = (event) => {
    setTimeout(() => {
      fetchSource(pagination);
      event.detail.complete();
    }, 2000);
  };

  const fetchSource = async (paginationObj) => {
    setLoading(true);
    try {
      const resp = await fetchSourceApi({
        page: paginationObj.current,
        pageSize: paginationObj.pageSize,
        memberNos,
        ...idObj,
      });
      if (!resp.success) return;
      setDataSource(resp.data.data.array);
      setPagination({
        ...pagination,
        current: paginationObj.current,
        pageSize: paginationObj.pageSize,
        total: resp.data.data.totalCount,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const postRecord = async ({ id, contactId, attendType }) => {
    setLoading(true);
    const postBody =
      category === "event"
        ? {
            eventEnrollmentId: id,
            contactId,
            attendType,
            addBy: APP.auth.userId,
          }
        : category === "education"
        ? {
            educationClassEnrollmentId: id,
            contactId,
            attendType,
            addBy: APP.auth.userId,
          }
        : {
            cpdActivityId: parseInt(urlId),
            contactId,
            attendType,
            addBy: APP.auth.userId,
          };
    try {
      const resp = await postApi(postBody);
      if (!resp.success) {
        dispatch(
          showModal({
            key: "warning",
            msg: [{ msg: resp.message }],
          })
        );
      }
      dispatch(
        showModal({
          key: "success",
          msg: `${
            attendType === "in"
              ? "Successfully Attended"
              : "Successfully Departed"
          }`,
        })
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const defineClass = () => {
    let title = "";
    let dataIndex = "";
    let key = "";
    switch (category) {
      case "event":
        title = "t_event";
        dataIndex = "event";
        key = "event";
        break;
      case "education":
        title = "t_educationClass";
        dataIndex = "educationClass";
        key = "educationClass";
        break;
      case "cpd":
        title = "t_activity";
        dataIndex = "cpdActivity";
        key = "cpdActivity";
        break;
      default:
    }

    return { title, dataIndex, key };
  };

  const openModal = (record, attendType) => {
    setIsModalOpen(true);
    setRecord({ ...record, attendType });
  };

  const handleOk = () => {
    postRecord({
      id: record.id,
      attendType: record.attendType,
      contactId: record.contactId,
    });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setRecord(null);
  };

  const handleQuickOnChange = (e) => {
    setMemberNos(e.target.value);
  };

  const handleOnSearch = () => {
    fetchSource({
      current: 1,
      pageSize: 10,
      total: 0,
      position: ["bottomCenter"],
    });
  };

  const columns = [
    {
      title: t("t_memberNo"),
      dataIndex: "memberNo",
      key: "memberNo",
      render: (_) => {
        return _ ? _ : "-";
      },
    },
    {
      title: t("t_fullName"),
      dataIndex: "fullName",
      key: "fullName",
      render: (_, record) => {
        return (
          <span>
            {currentLanguage
              ? `${record?.lastName || "-"} ${record?.firstName || ""}`
              : record?.chineseName || "-"}
          </span>
        );
      },
    },
    {
      title: t("t_phoneNum"),
      dataIndex: "mobilePhone",
      key: "mobilePhone",
      render: (_) => {
        return _ ? _ : "-";
      },
    },
    {
      title: t("t_email"),
      dataIndex: "email",
      key: "email",
      render: (_) => {
        return _ ? _ : "-";
      },
    },
    {
      title: t(defineClass().title),
      dataIndex: defineClass().dataIndex,
      key: defineClass().dataIndex,
      render: (_, record) => {
        return currentLanguage
          ? record.titleEn || record.titleTc
          : record.titleTc || record.titleEn;
      },
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <Row>
        <Col span={12}>
          <Button
            className={styles.attendance}
            onClick={() => openModal(record, "in")}
          >
            {t("t_attendance")}
          </Button>
        </Col>
        <Col span={12}>
          <Button
            className={styles.departure}
            onClick={() => openModal(record, "out")}
          >
            {t("t_departure")}
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <IonPage id="main-content">
        <IonHeader>
          <TickTitleBar
            title="t_manualAttendance"
            onGoBack={() => history.push(`/masAttendance/${category}`)}
            rightBtnContent={urlId && "t_record"}
            onRightClick={() =>
              history.push(
                `/masAttendance/record?category=${category}&id=${urlId}`
              )
            }
          />
        </IonHeader>
        <IonContent className={styles.homeBg}>
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <div className={styles.mainContainer}>
            <Row className={styles.quickSearch}>
              <Col span={18}>
                <Input
                  placeholder={t("t_manualQuickSearch")}
                  onChange={handleQuickOnChange}
                  value={memberNos}
                  allowClear
                />
              </Col>
              <Col span={6}>
                <Button type="primary" onClick={handleOnSearch}>
                  {t("t_search")}
                </Button>
              </Col>
            </Row>

            <Table
              columns={columns}
              expandable={{
                expandedRowRender,
                defaultExpandedRowKeys: ["0"],
              }}
              dataSource={dataSource}
              loading={loading}
              pagination={pagination}
              onChange={fetchSource}
              rowKey={(record) => record.id}
              scroll={{ x: "400px" }}
            />
          </div>
        </IonContent>
      </IonPage>
      <Modal
        open={isModalOpen}
        title="Confirm To Update"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure update this User ?</p>
        <Row>
          <Col span={8}>MemberNo:</Col>
          <Col span={16}>{record?.memberNo}</Col>
          <Col span={8}>Full Name:</Col>
          <Col span={16}>{`${record?.lastName || "-"} ${
            record?.firstName || ""
          }`}</Col>
          <Col span={8}>Status:</Col>
          <Col span={16}>
            <span
              style={{
                background:
                  record?.attendType === "in" ? "lightgreen" : "#FFCCCB",
                color: "white",
                padding: "2px 8px",
                border:
                  record?.attendType === "in"
                    ? "1px solid green"
                    : "1px solid red",
              }}
            >
              {record?.attendType === "in" ? "In" : "Out"}
            </span>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Manual;
