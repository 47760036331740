import React, { useState, useContext } from "react";
import TickLocalModal from "../../components/Modal/TickLocalModal";
import TickTitleBar from "../../components/TickTitleBar/TickTitleBar";
import styles from "./SettingModal.module.scss";
import _ from "lodash";
import { IonContent, IonLabel, IonList, IonItem } from "@ionic/react";
import { useTranslation } from "react-i18next";
import RadioButton from "../../components/RadioButton/RadioButton";
import { AppSettingContext } from "../../providers/AppSettingsProvider";
import { languageSet, fontSizeKeySet } from "../../config";
import useSize from "../../hooks/useSize";
import { enCrypt } from "../../helpers/helpers";

const SettingModal = (props) => {
  const { visible = false, setVisible = () => {} } = props;
  const [settingValue, setSettingValue] = useState({});

  const { t } = useTranslation();
  const { setAppSettings } = useContext(AppSettingContext);
  const [fontSize] = useSize();

  const fields = [
    {
      label: "t_language",
      key: "language",
      options: languageSet,
    },
    // {
    //   label: "t_font_size",
    //   key: "fontSize",
    //   options: fontSizeKeySet,
    // },
  ];

  const RenderToggle = () => {
    return fields.map((item, index) => {
      return (
        <IonItem key={`setting_toggles_${index}`}>
          <div className={styles.fieldContainer}>
            <IonLabel style={{ ...fontSize(16), flex: 1 }}>
              {t(item.label)}
            </IonLabel>
            {/* <IonLabel>{t(item.label)}</IonLabel> */}
            <div className={styles.btnGroup}>
              <RadioButton
                options={item.options}
                setSettingValue={setSettingValue}
                settingValue={settingValue}
                settingKey={item.key}
              />
            </div>
          </div>
        </IonItem>
      );
    });
  };

  const save = () => {
    localStorage.setItem("clientSettings", enCrypt(settingValue));
    setAppSettings(settingValue);
    setVisible(false);
  };

  return (
    <TickLocalModal visible={visible}>
      <div style={{ height: "100%", width: "100%" }}>
        <TickTitleBar
          title={t("t_setting")}
          // theme="blue"
          onGoBack={() => setVisible(false)}
          onRightClick={save}
          rightBtnContent={t("t_save")}
        />
        <IonContent>
          <IonList>{RenderToggle()}</IonList>
        </IonContent>
      </div>
    </TickLocalModal>
  );
};

export default SettingModal;
