import { useEffect, useState } from "react";
import { IonItem, IonSelect, IonSelectOption } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { fetchEventType, fetchEvent } from "../../services/apis/eventApis";
import {
  fetchEducationClass,
  fetchEducationCourseType,
} from "../../services/apis/educationApis";
import { authorityBodyEnum } from "../../enum/authorityBody";
import { cpdActivityListSelectOption } from "../../services/apis/cpdApi";

const TickSelect = (props) => {
  const {
    label = "",
    setDetail = () => {},
    detail = null,
    params,
    type,
    showId,
    disabled = false,
  } = props;
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    list(type);
  }, [params]);

  function list(type) {
    switch (type) {
      case "eventType":
        fetchEventType()
          .then((resp) => {
            let temp = [];
            if (Array.isArray(resp?.data?.data)) {
              resp?.data?.data.forEach((item) => {
                temp.push({
                  value: item?.id,
                  name: item?.name,
                });
              });
            }
            setOptions(temp);
          })
          .catch((e) => console.error(e));
        break;
      case "event":
        if (!params) return;
        fetchEvent(params)
          .then((resp) => {
            let temp = [];
            if (Array.isArray(resp?.data?.data)) {
              resp?.data?.data.forEach((item) => {
                temp.push({
                  value: item?.id,
                  name: item?.eventNameEn,
                });
              });
            }
            setOptions(temp);
          })
          .catch((e) => console.error(e));
        break;
      case "educationCourseType":
        fetchEducationCourseType()
          .then((resp) => {
            let temp = [];
            if (Array.isArray(resp?.data?.data)) {
              resp?.data?.data.forEach((item) => {
                temp.push({
                  value: item?.id,
                  name: item?.name,
                });
              });
            }
            setOptions(temp);
          })
          .catch((e) => console.error(e));
        break;
      case "educationClass":
        if (!params) return;
        fetchEducationClass(params)
          .then((resp) => {
            let temp = [];
            if (Array.isArray(resp?.data?.data)) {
              resp?.data?.data.forEach((item) => {
                temp.push({
                  value: item?.id,
                  name: item?.className,
                  temp: item.classCode,
                });
              });
              setOptions(temp);
            } else {
              const tempObj = {
                value: resp?.data?.data?.id,
                name: resp?.data?.data?.className,
                temp: resp?.data?.data?.classCode,
              };
              setOptions([tempObj]);
            }
          })
          .catch((e) => console.error(e));
        break;
      case "authorityBody":
        let temp = [];
        Object.entries(authorityBodyEnum).forEach((item) => {
          temp.push({
            value: parseInt(item[0]),
            name: item[1],
          });
        });
        setOptions(temp);
        break;
      case "activityCode":
        if (params === null) return;
        cpdActivityListSelectOption(params)
          .then((resp) => {
            let temp = [];
            if (Array.isArray(resp?.data?.data)) {
              resp?.data?.data.forEach((item) => {
                temp.push({
                  value: item?.id,
                  name: item?.code,
                  temp: item.title,
                });
              });
              setOptions(temp);
            } else {
              const tempObj = {
                value: resp?.data?.data?.id,
                name: resp?.data?.data?.code,
                temp: resp?.data?.data?.title,
              };
              setOptions([tempObj]);
            }
          })
          .catch((e) => console.error(e));
        break;
      default:
    }
  }

  return (
    <IonItem>
      <ion-label>{t(label)}</ion-label>
      <IonSelect
        placeholder={t("t_pleaseSelectOne")}
        onIonChange={(e) => setDetail(e.target.value)}
        value={detail}
        disabled={disabled}
      >
        {options.map((item, idx) => {
          return (
            <IonSelectOption value={item.value} key={`options_${idx}`}>
              {showId ? `${item.name}(${item.temp})` : item.name}
            </IonSelectOption>
          );
        })}
      </IonSelect>
    </IonItem>
  );
};

export default TickSelect;
