import React, { useEffect, useState } from "react";
import { IonApp, IonButton, IonBackdrop, IonContent } from "@ionic/react";
import TickLocalModal from "../../components/Modal/TickLocalModal";
import TickTitleBar from "../../components/TickTitleBar/TickTitleBar";
import { useSelector } from "react-redux";
import _ from "lodash";

const LogRespHistory = (props) => {
  const { setVisible, visible } = props;
  const [showDetail, setShowDetail] = useState(false);
  const [detail, setDetail] = useState(null);
  const respHistory = useSelector((state) => state.respHistory.history);

  return (
    <TickLocalModal visible={visible}>
      <TickTitleBar onGoBack={setVisible} title="RespHistory" />
      <IonContent>
        {_.map(respHistory, (item, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "4px 8px",
              }}
              onClick={() => {
                setDetail(item);
                setShowDetail(true);
              }}
            >
              <div style={{ flex: 1 }}>{item.api.toString()}</div>
              <div style={{ flex: 1, textAlign: "end" }}>
                {item.time.toString()}
              </div>
            </div>
          );
        })}
      </IonContent>
      {showDetail && (
        <Detail
          visible={showDetail}
          setVisible={() => setShowDetail(false)}
          detail={detail}
        />
      )}
    </TickLocalModal>
  );
};

export default LogRespHistory;

const Detail = (props) => {
  const { detail = null, visible = false, setVisible } = props;
  const handleDetails = () => {
    let newDetails = JSON.stringify(detail, null, 2).replace(/,/g, "\n");
    return newDetails;
  };
  return (
    <TickLocalModal visible={visible}>
      <TickTitleBar onGoBack={setVisible} title="Resp Details" />
      <IonContent>
        <pre style={{ width: "100%", overflow: "initial" }}>
          {handleDetails()}
        </pre>
      </IonContent>
    </TickLocalModal>
  );
};
