import { IonButtons, IonMenuButton, IonTitle, IonToolbar } from "@ionic/react";
import logout from "../../assets/icons/logout_icon.svg";
import leftArrow from "../../assets/icons/arrow_left_icon.svg";
import crossIcon from "../../assets/icons/crossIcon.svg";
import styles from "./tickTitleBar.module.scss";

import { useTranslation } from "react-i18next";
import useSize from "../../hooks/useSize";
import { handleTextCase } from "../../helpers/helpers";

const TickTitleBar = (props) => {
  const {
    title = "Please input title",
    homeBar = false,
    theme = "red",
    onExit = () => {},
    onGoBack = () => {},
    rightBtnContent = null,
    onRightClick = () => {},
    isCrossIcon = false,
  } = props;

  const { t } = useTranslation();
  const [ fontSize, imgWidth ] = useSize();

  if (homeBar) {
    return (
      <IonToolbar
        className={`${styles.tickTitleBarContainer} ${styles[theme]}`}
      >
        <div className={styles.contentContainer}>
          {/* only heading */}
          {/* <div className={styles.title} style={fontSize(16)}>{handleTextCase(t(title))}</div> */}
          {/* heading with logged in user */}
          <div className={styles.title} style={fontSize(16)}>{`${handleTextCase(t(title))}`}</div>
          {/* <div className={styles.exitBtn} onClick={onExit}> */}
            {/* <img src={logout} alt="logout-icon" style={imgWidth(24)}/> */}
            <IonButtons slot="start">
              <IonMenuButton style={{...fontSize(24), color: 'white'}}></IonMenuButton>
            </IonButtons>
          {/* </div> */}
        </div>
      </IonToolbar>
    );
  }

  return (
    <IonToolbar className={`${styles.tickTitleBarContainer} ${styles[theme]}`}>
      <div className={styles.contentContainer}>
        {isCrossIcon ? (
          <div className={`${styles.btn} ${styles[theme]}`} onClick={onGoBack}>
            <img src={crossIcon} alt="cross-icon" style={imgWidth(24)}/>
          </div>
        ) : (
          <div className={`${styles.btn} ${styles[theme]}`} onClick={onGoBack}>
            <img src={leftArrow} alt="arrow-left-icon" style={imgWidth(24)}/>
          </div>
        )}

        {/* <IonTitle> */}
          <div className={styles.title} style={fontSize(16)}>{t(title)}</div>
        {/* </IonTitle> */}
        {rightBtnContent ? (
          <div
            className={styles.confirmBtn}
            onClick={rightBtnContent ? onRightClick : () => {}}
            style={fontSize(16)}
          >
            {t(rightBtnContent)}
          </div>
        ) : (
          <div style={{ width: 80 }}></div>
        )}
      </div>
    </IonToolbar>
  );
};

export default TickTitleBar;
