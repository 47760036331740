import {
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import { useState } from "react";
import TickTitleBar from "../../components/TickTitleBar/TickTitleBar";
import { APP } from "../../env";

import styles from "./Main.module.scss";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import Event from "../../assets/icons/Event.svg";
import Education from "../../assets/icons/Education.svg";
import CPD from "../../assets/icons/CPD.svg";
import Setting from "../../assets/icons/setting_icon_grey.svg";
import Logout from "../../assets/icons/logout_icon_grey.svg";
import SettingModal from "../../Modals/SettingModal/SettingModal";
import { Col, Row } from "antd";
import { enCrypt } from "../../helpers/helpers";
import { defaultSelectedAttendInfo, defaultSettings } from "../../config";

const Main = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [showSetting, setShowSetting] = useState(false);

  const handleRefresh = (event) => {
    setTimeout(() => {
      event.detail.complete();
    }, 2000);
  };

  const handleLogout = () => {
    localStorage.removeItem("userInfo");
    localStorage.setItem("clientSettings", enCrypt(defaultSettings));
    localStorage.setItem(
      "selectAttendInfo",
      enCrypt(defaultSelectedAttendInfo)
    );
    APP.auth = {
      userId: null,
      userType: null,
      accessToken: null,
      isAuth: false,
      userName: null,
    };
    history.push("/");
  };

  const list = [
    {
      function: () => history.push("/masAttendance/event"),
      imgSrc: Event,
      alt: "event_icon",
      title: "t_event",
      span: 12,
    },
    {
      function: () => history.push("/masAttendance/education"),
      imgSrc: Education,
      alt: "education_icon",
      title: "t_education",
      span: 12,
    },
    {
      function: () => history.push("/masAttendance/cpd"),
      imgSrc: CPD,
      alt: "education_icon",
      title: "t_cpd",
      span: 24,
    },
    [
      {
        function: () => setShowSetting(true),
        imgSrc: Setting,
        alt: "setting_icon",
        title: "t_setting",
        span: 12,
      },
      {
        function: handleLogout,
        imgSrc: Logout,
        alt: "logout_icon",
        title: "t_logout",
        span: 12,
      },
    ],
  ];

  return (
    <>
      <IonPage id="main-content">
        <IonHeader>
          <TickTitleBar homeBar title="t_menu" onExit />
        </IonHeader>
        <IonContent className={styles.homeBg}>
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <div className={styles.mainContainer}>
            <div className={styles.cardWrapper}>
              <Row>
                {list.map((item, key) => {
                  if (Array.isArray(item)) {
                    return (
                      <Row style={{ width: "100%" }} key={`item_${key}`}>
                        {item.map((nestItem, nestKey) => {
                          return (
                            <Col span={nestItem.span} key={`item_${nestKey}`}>
                              <div className={styles.row}>
                                <IonCard
                                  className={styles.card}
                                  onClick={nestItem.function}
                                >
                                  <img
                                    src={nestItem.imgSrc}
                                    alt={nestItem.alt}
                                    style={{ width: 100, height: 100 }}
                                  />
                                  <IonCardHeader>
                                    <IonCardTitle>
                                      {t(nestItem.title)}
                                    </IonCardTitle>
                                  </IonCardHeader>
                                </IonCard>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    );
                  }
                  return (
                    <Col span={item.span} key={`item_${key}`}>
                      <div className={styles.row}>
                        <IonCard
                          className={styles.card}
                          onClick={item.function}
                        >
                          <img
                            src={item.imgSrc}
                            alt={item.alt}
                            style={{ width: 100, height: 100 }}
                          />
                          <IonCardHeader>
                            <IonCardTitle>{t(item.title)}</IonCardTitle>
                          </IonCardHeader>
                        </IonCard>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </IonContent>
      </IonPage>
      <SettingModal visible={showSetting} setVisible={setShowSetting} />
    </>
  );
};

export default Main;
