import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  auth: {
    userId: null,
    accessToken: null,
    isAuth: false
  }
};

export const authSlice = createSlice({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    setAuth: (state, newState) => {
      state.auth = newState.payload;
    },

    clearAuth: () => initialState,
  },
});

export const { setAuth, clearAuth } = authSlice.actions;

export default authSlice.reducer;