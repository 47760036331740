export const defineErrorCase = (res, callback, callbackForRescan) => {
  switch (res?.code) {
    case 1:
      return {
        key: "warning",
        msg: [{ msg: res?.message }],
        btnText: "Login Again",
        // showCloseBtn: true,
        callback,
      };
    case "Token invalid":
      return {
        key: "warning",
        msg: [{ msg: res?.msg }],
        btnText: "Login Again",
        // showCloseBtn: true,
        callback,
      };
    case "NETWORK_ERROR":
      return {
        key: "warning",
        msg: [{ msg: res?.msg }],
        // btnText: 'Retry',
        showCloseBtn: true,
      };
    default:
      return {
        key: "warning",
        msg: [{ msg: res?.msg || "NETWORK ERROR" }],
        showCloseBtn: true,
        btnText: callbackForRescan && "Try Again",
        callback: callbackForRescan && callbackForRescan,
      };
  }
};
