import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthRoute from "./Routes/AuthRoute";
import Login from "./pages/Login/Login";
import TickSuccessModal from "./components/Modal/TickSuccessModal";
import TickLoadingModal from "./components/Modal/TickLoadingModal";
import TickWarningModal from "./components/Modal/TickWarningModal";

import UnitTest from "./pages/UnitTest/UnitTest";
import Landing from "./pages/Landing/Landing";
import Main from "./pages/Main/Main";
import Event from "./pages/Event/Event";
import Education from "./pages/Education/Education";
import Record from "./pages/Record/Record";
import { IonReactRouter } from "@ionic/react-router";
import CPD from "./pages/CPD/CPD";
import Manual from "./pages/Manual/Manual";

const AppRoute = () => {
  const tickGlobalModalState = useSelector((state) => state.tickModal);

  return (
    <>
      <IonReactRouter>
        <Switch>
          <Route exact path="/" render={() => <Landing />} />
          <Route exact path="/masAttendance/login">
            <Login />
          </Route>
          <AuthRoute exact path="/masAttendance/main">
            <Main />
          </AuthRoute>
          <AuthRoute exact path="/masAttendance/event">
            <Event />
          </AuthRoute>
          <AuthRoute exact path="/masAttendance/education">
            <Education />
          </AuthRoute>
          <AuthRoute exact path="/masAttendance/cpd">
            <CPD />
          </AuthRoute>
          <AuthRoute exact path="/masAttendance/record">
            <Record />
          </AuthRoute>
          <AuthRoute exact path="/masAttendance/manualAttendance">
            <Manual />
          </AuthRoute>
          <Route exact path="/masAttendance/unit-test">
            <UnitTest />
          </Route>
          {/* no matched path redirect */}
          <Route render={() => <Redirect to="/" />} />
          {/* no matched path redirect */}
        </Switch>
      </IonReactRouter>

      {tickGlobalModalState.show &&
      tickGlobalModalState?.params?.key === "success" ? (
        <TickSuccessModal />
      ) : null}
      {tickGlobalModalState.show &&
      tickGlobalModalState?.params?.key === "loading" ? (
        <TickLoadingModal />
      ) : null}
      {tickGlobalModalState.show &&
      tickGlobalModalState?.params?.key === "warning" ? (
        <TickWarningModal />
      ) : null}
    </>
  );
};

export default AppRoute;
