import package_json from "../package.json";

export const APP = {
  env: "uat",
  auth: {
    accessToken: null,
    userId: null,
    isAuth: false,
    userName: null,
    userType: null,
  },
  version: `V ${package_json.version}`,
  forEnterUnitTestCode: null,
};

export const envConfig = {
  local: {
    PROTOCOL: "http://",
    API_HOST: "localhost:7100",
    // PROTOCOL: "https://",
    // API_HOST: "api-uat.luahk.surpasstailor.com",
  },
  dev: {
    PROTOCOL: "https://",
    API_HOST: "api-dev.luahk.surpasstailor.com",
    // PROTOCOL: "https://",
    // API_HOST: "api-uat.luahk.surpasstailor.com",
  },
  uat: {
    PROTOCOL: "https://",
    API_HOST: "api-uat.luahk.surpasstailor.com",
  },
  prod: {
    PROTOCOL: "https://",
    API_HOST: "api.luahk.surpasstailor.com",
  },
};

export const SUFFIX = "/api/mas";

export const endpoint = {
  baseURL: envConfig[APP.env].PROTOCOL + envConfig[APP.env].API_HOST,
};
