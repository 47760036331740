import React from "react";
import { Redirect, Route } from "react-router-dom";
import { APP } from "../env";

const AuthRoute = ({ children, isLogin = false, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => {
        return APP?.auth?.isAuth ? (
          children
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};

export default AuthRoute;
