import { useState, useEffect } from "react";
import { IonButton, IonPage, IonContent, IonFooter } from "@ionic/react";
import { useTranslation } from "react-i18next";

import TickTitleBar from "../../components/TickTitleBar/TickTitleBar";
import styles from "./unitTest.module.scss";

import { endpoint, APP, envConfig } from "../../env";
import { useHistory } from "react-router-dom";

import i18n from "../../i18n";
import ScanModal from "../../Modals/ScanModal/ScanModal";
import LogRespHistory from "./LogRespHistory";
import useDispatchRespHistory from "../../hooks/useDispatchRespHistory";

function UnitTest() {
  const { t } = useTranslation();
  const [serverManifestVNum, setServerManifestVNum] = useState("");
  const [language, setLanguage] = useState("en");
  const [serverEnv, setServerEnv] = useState(APP.env);
  const [showLogRespHistory, setShowLogRespHistory] = useState(false);
  const [isAttendanceModalVisible, setIsAttendanceModalVisible] =
    useState(false);
  const [isDepartureModalVisible, setIsDepartureModalVisible] = useState(false);

  const history = useHistory();
  const dispatchRespHistory = useDispatchRespHistory();

  i18n.on("languageChanged", (lng) => setLanguage(i18n.language));
  useEffect(() => {
    if (APP.env === "prod") {
      history.push("/");
    }
  }, []);

  const changeLocale = (l) => {
    if (language !== l) {
      i18n.changeLanguage(l);
    }
  };

  const handleAttendanceModalVisible = () => {
    setIsAttendanceModalVisible(!isAttendanceModalVisible);
  };

  const handleDepartureModalVisible = () => {
    setIsDepartureModalVisible(!isDepartureModalVisible);
  };

  const handleScannedValueIsValid = (data) => {
    let isValid = false;
    if (data.classCode === "123") {
      isValid = true;
    }
    dispatchRespHistory({ data }, "null", "scan Objet");
    return isValid;
  };

  return (
    <IonPage id="main-content">
      <IonContent fullscreen className={styles.container}>
        <TickTitleBar
          // theme="blue"
          title="Unit Test"
          onGoBack={() => {
            history.push("/");
            APP.forEnterUnitTestCode = null;
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "16px",
          }}
        >
          <div className={styles.text}>{`Version: ${APP.version}`}</div>
          <div className={styles.text}>{`Env: ${serverEnv}`}</div>
          <div className={styles.text}>{`Endpoint: ${endpoint.baseURL}`}</div>
          <div
            className={styles.text}
          >{`localStorage App Token: ${localStorage.getItem("userInfo")}`}</div>
          <div className={styles.text}>{`Token: ${APP.auth.accessToken}`}</div>
          <div
            className={styles.text}
          >{`ServerManifestVNum: ${serverManifestVNum}`}</div>
          <div className={styles.text}>{`Language: ${t(language)}, example: ${t(
            "hello"
          )}`}</div>
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          <IonButton
            onClick={() =>
              APP.env == "dev"
                ? ((APP.env = "uat"),
                  setServerEnv(APP.env),
                  (endpoint.baseURL =
                    envConfig[APP.env].PROTOCOL +
                    envConfig[APP?.env]?.API_HOST))
                : APP.env == "uat"
                ? ((APP.env = "prod"),
                  setServerEnv(APP.env),
                  (endpoint.baseURL =
                    envConfig[APP.env].PROTOCOL +
                    envConfig[APP?.env]?.API_HOST))
                : APP.env == "prod"
                ? ((APP.env = "dev"),
                  setServerEnv(APP.env),
                  (endpoint.baseURL =
                    envConfig[APP.env].PROTOCOL +
                    envConfig[APP?.env]?.API_HOST))
                : ""
            }
          >
            Change Env
          </IonButton>
          <IonButton
            onClick={() => {
              if (language == "en") {
                changeLocale("tc_hk");
              } else {
                changeLocale("en");
              }
            }}
          >
            Change Language
          </IonButton>
          <div>
            <IonButton onClick={() => setShowLogRespHistory(true)}>
              Log
            </IonButton>
          </div>
        </div>
      </IonContent>
      {/*---------------------------------------------------Test Content-------------------------------------------- */}
      <IonFooter>
        <IonButton expand="block" onClick={handleAttendanceModalVisible}>
          Attendance Modal
        </IonButton>
        <IonButton expand="block" onClick={handleDepartureModalVisible}>
          Departure Modal
        </IonButton>
      </IonFooter>
      <div>
        {isAttendanceModalVisible ? (
          <ScanModal
            visible={isAttendanceModalVisible}
            setVisible={setIsAttendanceModalVisible}
            handleScannedValueIsValid={handleScannedValueIsValid}
            isAttendance
          />
        ) : null}
        {isDepartureModalVisible ? (
          <ScanModal
            visible={isDepartureModalVisible}
            setVisible={setIsDepartureModalVisible}
            handleScannedValueIsValid={handleScannedValueIsValid}
          />
        ) : null}
      </div>
      {showLogRespHistory && (
        <LogRespHistory
          visible={showLogRespHistory}
          setVisible={() => setShowLogRespHistory(false)}
        />
      )}
    </IonPage>
  );
}

export default UnitTest;
