import { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import TickTitleBar from "../../components/TickTitleBar/TickTitleBar";
import styles from "./Record.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { eventDelete, eventListByPage } from "../../services/apis/eventApis";
import {
  educationDelete,
  educationListByPage,
} from "../../services/apis/educationApis";
import { Table, Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { cpdAttendanceListByPage, cpdDelete } from "../../services/apis/cpdApi";

const Record = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ["bottomCenter"],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recordId, setRecordId] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const category = new URLSearchParams(location.search).get("category");
  const id = new URLSearchParams(location.search).get("id");

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language === "en";

  const fetchSourceApi =
    category === "event"
      ? eventListByPage
      : category === "education"
      ? educationListByPage
      : cpdAttendanceListByPage;

  const deleteRecordApi =
    category === "event"
      ? eventDelete
      : category === "education"
      ? educationDelete
      : cpdDelete;

  const idObj =
    category === "event"
      ? { eventId: id }
      : category === "education"
      ? { educationClassId: id }
      : { cpdActivityId: id };

  useEffect(() => {
    fetchSource(pagination);
  }, []);

  const handleRefresh = (event) => {
    setTimeout(() => {
      fetchSource(pagination);
      event.detail.complete();
    }, 2000);
  };

  const fetchSource = async (paginationObj) => {
    setLoading(true);
    try {
      const resp = await fetchSourceApi({
        page: paginationObj.current,
        pageSize: paginationObj.pageSize,
        ...idObj,
      });
      if (!resp.success) return;
      if (!resp?.data?.data?.array?.memberNo) {
        const newDataSource = [];
        resp.data.data.array.forEach((item) => {
          const itemObj = { ...item };
          itemObj.memberNo = item?.contact?.memberNo || "-";
          newDataSource.push(itemObj);
        });
        setDataSource(newDataSource);
      } else {
        setDataSource(resp.data.data.array);
      }
      setPagination({
        ...pagination,
        current: paginationObj.current,
        pageSize: paginationObj.pageSize,
        total: resp.data.data.totalCount,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const defineClass = () => {
    let title = "";
    let dataIndex = "";
    let key = "";
    switch (category) {
      case "event":
        title = "t_event";
        dataIndex = "event";
        key = "event";
        break;
      case "education":
        title = "t_educationClass";
        dataIndex = "educationClass";
        key = "educationClass";
        break;
      case "cpd":
        title = "t_activity";
        dataIndex = "cpdActivity";
        key = "cpdActivity";
        break;
      default:
    }

    return { title, dataIndex, key };
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const resp = await deleteRecordApi(id);
      if (!resp.success) return;
      fetchSource(pagination);
    } catch (error) {
      console.error("::::Something wrong occurred in record page");
    } finally {
      setLoading(false);
    }
  };

  const showModal = (id) => {
    setIsModalOpen(true);
    setRecordId(id);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    handleDelete(recordId);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: t("t_memberNo"),
      dataIndex: "memberNo",
      key: "memberNo",
    },
    {
      title: t("t_fullName"),
      dataIndex: "fullName",
      key: "fullName",
      render: (_, record) => {
        return (
          <span>
            {currentLanguage
              ? `${record?.contact?.lastName} ${record?.contact?.firstName}`
              : record?.contact?.chineseName}
          </span>
        );
      },
    },
    {
      title: t("t_phoneNum"),
      dataIndex: "phoneNum",
      key: "phoneNum",
      render: (_, record) => {
        return <span>{record?.contact?.mobilePhone || "-"}</span>;
      },
    },
    {
      title: t("t_email"),
      dataIndex: "email",
      key: "email",
      render: (_, record) => {
        return <span>{record?.contact?.email || "-"}</span>;
      },
    },
    {
      title: t(defineClass().title),
      dataIndex: defineClass().dataIndex,
      key: defineClass().dataIndex,
    },
    {
      title: t("t_attendType"),
      dataIndex: "attendType",
      key: "attendType",
      render: (_) => (
        <span
          style={{
            background: _ === "in" ? "lightgreen" : "#FFCCCB",
            color: "white",
            padding: "2px 8px",
            border: _ === "in" ? "1px solid green" : "1px solid red",
          }}
        >
          {_ === "in" ? "In" : "Out"}
        </span>
      ),
    },
    {
      title: t("t_attendTime"),
      dataIndex: "attendTime",
      key: "attendTime",
      render: (_) => moment(_).format("YYYY-MM-DD hh:mm:ss"),
    },
    {
      title: t("t_operation"),
      dataIndex: "operation",
      key: "operation",
      render: (_, record) => (
        <Button type="primary" onClick={() => showModal(record.id)}>
          {t("t_delete")}
        </Button>
      ),
    },
  ];
  return (
    <>
      <IonPage id="main-content">
        <IonHeader>
          <TickTitleBar title="t_record" onGoBack={() => history.goBack()} />
        </IonHeader>
        <IonContent className={styles.homeBg}>
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <div className={styles.mainContainer}>
            <Table
              columns={columns}
              dataSource={dataSource}
              loading={loading}
              pagination={pagination}
              onChange={fetchSource}
              rowKey={(record) => record.id}
              scroll={{ x: "400px" }}
            />
          </div>
        </IonContent>
      </IonPage>

      <Modal
        open={isModalOpen}
        title="Confirm To Delete"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure to delete this record ?</p>
      </Modal>
    </>
  );
};

export default Record;
