import { useEffect, useState, useRef } from "react";
import { IonContent, IonInput } from "@ionic/react";
import { useDispatch } from "react-redux";
import styles from "./Login.module.scss";
import { useHistory } from "react-router";
import { postLogin } from "../../services/apis/loginApis";
import { closeModal, showModal } from "../../redux/tickModalSlice";
import { defineErrorCase } from "../../helpers/errorCase";
import { APP } from "../../env";
import Logo from "../../assets/icons/Logo.svg";
import loginPasswordNonVisible from "../../assets/icons/loginPasswordNonVisible.svg";
import loginPasswordVisible from "../../assets/icons/loginPasswordVisible.svg";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

import settingIcon from "../../assets/icons/setting_icon.svg";
import SettingModal from "../../Modals/SettingModal/SettingModal";

import { useTranslation } from "react-i18next";
import useSize from "../../hooks/useSize";
import useDispatchRespHistory from "../../hooks/useDispatchRespHistory";
import * as CryptoJS from "crypto-js";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isKeyboardOpen = useDetectKeyboardOpen();
  const inputPasswordRef = useRef(null);

  let count = 0;
  const [toggleVisible, setToggleVisible] = useState("password");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showSetting, setShowSetting] = useState(false);

  const { t } = useTranslation();
  const [fontSize, imgWidth] = useSize();
  const dispatchRespHistory = useDispatchRespHistory();

  useEffect(() => {
    clearLoginSession();
  }, []);

  const navigateUnitTestScreen = () => {
    if (APP.env === "prod") return;
    let triggerTimes = 2;
    //Step 1. Add 1 to counter
    count++;
    //Step 2 if counter == 1, setTimeOut to 4s to clear the counter
    if (count === 1) {
      setTimeout(() => {
        count = 0;
      }, 4000);
    }
    //Step 3. Counter reach 10 times within 4 sec, then go to step 3, else return
    if (count !== triggerTimes) {
      if (count > triggerTimes) {
        count = 0;
      }
      return;
    }
    //Step 4. navigate to UnitTestScreen
    // setShowUnitTest(true);
    APP.forEnterUnitTestCode = "gldpwa-test-unit-code";
    history.push("/masAttendance/unit-test");
  };

  const handleLogin = async () => {
    dispatch(
      showModal({
        key: "loading",
      })
    );

    try {
      const resp = await postLogin({
        username,
        password: CryptoJS.SHA256(password).toString(),
        rememberMe: false,
      });
      dispatchRespHistory(
        {
          username,
          password: CryptoJS.SHA256(password).toString(),
          rememberMe: false,
        },
        resp,
        "Login Record"
      );
      if (resp.message !== "Success") {
        dispatch(closeModal());
        let modalParams = defineErrorCase({
          code: 1,
          message: "Incorrect username or password",
        });
        dispatch(showModal(modalParams));

        //reset login useState
        setPassword("");
        setToggleVisible("password");
        return;
      }

      let authObj = {
        userId: resp?.data?.roles[0]?.id,
        userType: resp?.data?.roles[0]?.name,
        isAuth: true,
        userName: username,
      };

      APP.auth = {...APP.auth, ...authObj};
      localStorage.setItem("userInfo", JSON.stringify(APP.auth));
      history.push("/");
      dispatch(closeModal());

      //reset login useState
      setUsername("");
      setPassword("");
      setToggleVisible("password");
    } catch (e) {
      console.error(e);
    }
  };

  const clearLoginSession = () => {
    localStorage.removeItem("userInfo");
    APP.auth = {
      userId: null,
      userType: null,
      accessToken: null,
      isAuth: false,
      userName: null,
    };
  };

  function handleKeyPress(event, nextInputRef) {
    if (event.key === "Enter") {
      event.preventDefault();
      nextInputRef.current.setFocus();
    }
  }

  function handleKeyPressSubmit(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin();
    }
  }

  return (
    // <IonPage>
    <IonContent>
      <div className={styles.container}>
        <div
          className={styles.upperContainer}
          style={{ display: isKeyboardOpen ? "none" : "flex" }}
        >
          <div>
            <img
              style={imgWidth(90)}
              className={styles.logo}
              src={Logo}
              alt="logo"
              onClick={navigateUnitTestScreen}
            />
          </div>
          <h1 style={{ ...fontSize(16), fontWeight: 600 }}>
            MAS Attendance System
          </h1>
          <div style={fontSize(16)}>
            <b>MAS Attendance</b>
          </div>
          <div style={fontSize(16)}>{APP.version}</div>
          <div
            className={styles.settingContainer}
            onClick={() => setShowSetting(true)}
          >
            <div className={styles.innerContainer}>
              <img
                src={settingIcon}
                alt="setting_icon"
                style={imgWidth(16)}
                className={styles.settingIcon}
              />
              <span
                style={{
                  ...fontSize(16),
                  textTransform: "uppercase",
                  fontWeight: 600,
                }}
              >
                {t("t_setting")}
              </span>
            </div>
          </div>
        </div>
        {/* <div className={styles.bottomContainer}> */}
        <div
          className={styles.bottomContainer}
          style={{
            justifyContent: isKeyboardOpen ? "start" : "center",
            paddingTop: isKeyboardOpen ? "16px" : "0px",
          }}
        >
          <div className={styles.loginTextbox}>
            <div className={styles.inputText} style={fontSize(10)}>
              {t("t_userName")}
            </div>
            <IonInput
              type="text"
              // autofocus="true"
              onChangeText={(e) => console.log(e)}
              value={username}
              onIonChange={(e) => setUsername(e.detail.value)}
              style={fontSize(14)}
              className={styles.inputField}
              onKeyPress={(event) => handleKeyPress(event, inputPasswordRef)}
            ></IonInput>
          </div>
          <div className={styles.loginPasswordTextbox}>
            <div style={{ width: "100%" }}>
              <div className={styles.inputText} style={fontSize(10)}>
                {t("t_password")}
              </div>
              <IonInput
                ref={inputPasswordRef}
                type={toggleVisible}
                value={password}
                onIonChange={(e) => setPassword(e.detail.value)}
                style={fontSize(14)}
                className={styles.inputField}
                onKeyPress={(event) => handleKeyPressSubmit(event)}
              ></IonInput>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {toggleVisible == "password" ? (
                <img
                  src={loginPasswordNonVisible}
                  alt="loginPasswordNonVisible"
                  className={styles.nonVisibleOrVisibleIcon}
                  onClick={() => setToggleVisible("text")}
                  style={imgWidth(16)}
                />
              ) : (
                <img
                  src={loginPasswordVisible}
                  alt="loginPasswordVisible"
                  className={styles.nonVisibleOrVisibleIcon}
                  onClick={() => setToggleVisible("password")}
                  style={imgWidth(16)}
                />
              )}
            </div>
          </div>

          <button className={styles.loginButton} onClick={handleLogin}>
            <div style={fontSize(14)}>{t("t_login")}</div>
          </button>
        </div>
        {isKeyboardOpen && <div style={{ flex: 1 }}></div>}
      </div>
      <SettingModal visible={showSetting} setVisible={setShowSetting} />
    </IonContent>
    // </IonPage>
  );
};

export default Login;
