import { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonButton,
} from "@ionic/react";
import ScanModal from "../../Modals/ScanModal/ScanModal";
import TickTitleBar from "../../components/TickTitleBar/TickTitleBar";
import { useHistory } from "react-router-dom";
import styles from "./CPD.module.scss";
import { useTranslation } from "react-i18next";
import TickSelect from "../../components/TickSelect/TickSelect";
import TickSelectWithSearch from "../../components/TickSelectWithSearch/TickSelectWithSearch";
import { deCrypt, handleSelectAttendInfo } from "../../helpers/helpers";

const CPD = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [authorityBody, setAuthorityBody] = useState(null);
  const [activityCode, setActivityCode] = useState(null);

  const [isAttendanceModalVisible, setIsAttendanceModalVisible] =
    useState(false);
  const [isDepartureModalVisible, setIsDepartureModalVisible] = useState(false);

  useEffect(() => {
    deCryptStorage();
  }, []);

  const handleRefresh = (event) => {
    setTimeout(() => {
      clearState();
      event.detail.complete();
    }, 2000);
  };

  const handleAttendanceModalVisible = () => {
    setIsAttendanceModalVisible(!isAttendanceModalVisible);
  };

  const handleDepartureModalVisible = () => {
    setIsDepartureModalVisible(!isDepartureModalVisible);
  };

  const clearState = () => {
    setAuthorityBody(null);
    setActivityCode(null);
  };

  const handleAuthorityBody = (data) => {
    setAuthorityBody(data);
    handleSelectAttendInfo("authorityBody", data);
  };

  const handleActivityCode = (data) => {
    setActivityCode(data);
    handleSelectAttendInfo("activityCode", data);
  };

  const deCryptStorage = () => {
    const cpdActivityRecord = deCrypt(localStorage.getItem("selectAttendInfo"));
    setAuthorityBody(parseInt(cpdActivityRecord["authorityBody"]));
    setActivityCode(parseInt(cpdActivityRecord["activityCode"]));
  };

  return (
    <>
      <IonPage id="main-content">
        <IonHeader>
          <TickTitleBar
            title="t_cpd"
            onGoBack={() => history.push("/masAttendance/main")}
            rightBtnContent={activityCode && "t_record"}
            onRightClick={() =>
              history.push(
                `/masAttendance/record?category=cpd&id=${activityCode}`
              )
            }
          />
        </IonHeader>
        <IonContent className={styles.homeBg}>
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <div className={styles.mainContainer}>
            <div className={styles.upperWrapper}>
              <div>
                <TickSelectWithSearch
                  type="activityCode"
                  setFirstField={setAuthorityBody}
                  setSecondField={setActivityCode}
                  showId
                />
                <TickSelect
                  label={"t_authorityBody"}
                  setDetail={handleAuthorityBody}
                  detail={authorityBody}
                  type="authorityBody"
                />
                <TickSelect
                  label={"t_activity"}
                  setDetail={handleActivityCode}
                  detail={activityCode}
                  type="activityCode"
                  params={authorityBody}
                  disabled={authorityBody === null}
                  showId
                />
              </div>
              <IonButton
                expand="block"
                className="manual"
                disabled={activityCode ? false : true}
                onClick={() =>
                  history.push(
                    `/masAttendance/manualAttendance?category=cpd&id=${activityCode}`
                  )
                }
              >
                {t("t_manual")}
              </IonButton>
            </div>
            <div className={styles.bottomWrapper}>
              <IonButton
                expand="block"
                className="attendance"
                disabled={activityCode ? false : true}
                onClick={handleAttendanceModalVisible}
              >
                {t("t_attendance")}
              </IonButton>
              <IonButton
                expand="block"
                className="departure"
                disabled={activityCode ? false : true}
                onClick={handleDepartureModalVisible}
              >
                {t("t_departure")}
              </IonButton>
            </div>
          </div>
        </IonContent>
      </IonPage>
      <ScanModal
        visible={isAttendanceModalVisible}
        setVisible={setIsAttendanceModalVisible}
        clearState={clearState}
        isAttendance
        id={activityCode}
        type={"cpd"}
        details={[authorityBody, activityCode]}
      />
      <ScanModal
        visible={isDepartureModalVisible}
        setVisible={setIsDepartureModalVisible}
        clearState={clearState}
        id={activityCode}
        type={"cpd"}
      />
    </>
  );
};

export default CPD;
