import { MAG } from "../services";

const prefix = "/api/mas/cpd";

export const cpdAttendance = async (body) => {
  const endpoint = `${prefix}/attendance/save`;
  return await MAG.post(endpoint, { ...body });
};

// export const cpdAttendance = async (body) => {
//   const endpoint = `${prefix}/attendance/save/${body.cpdActivityId}/${body.contactId}/${body.attendType}/${body.addBy}`;
//   return await MAG.get(endpoint);
// };

// export const cpdActivityListSelectOption = async (authorityBody) => {
//   const endpoint = `${prefix}/activity/listSelectOptions?authorityBody=${authorityBody}`;
//   return await MAG.get(endpoint);
// };

export const cpdActivityListSelectOption = async (authorityBody) => {
  const endpoint = `${prefix}/activity/listSelectOptions/${authorityBody}`;
  return await MAG.get(endpoint);
};

export const cpdAttendanceListByPage = async (body) => {
  const { page, pageSize, cpdActivityId } = body;
  const endpoint = `${prefix}/attendance/listByPage/${page}/${pageSize}/${cpdActivityId}`;
  return await MAG.get(endpoint);
};

export const cpdDelete = async (id) => {
  const endpoint = `${prefix}/attendance/delete/${id}`;
  return await MAG.delete(endpoint);
};

export const cpdMemberListByPage = async (body) => {
  let endpoint = `${prefix}/member/listByPage/${body.page}/${body.pageSize}?`;
  if (body.memberNos) {
    endpoint += `memberNos=${body.memberNos}&`;
  }
  if (body.activityCodeId) {
    endpoint += `activityCodeId=${body.activityCodeId}&`;
  }
  return await MAG.get(endpoint);
};
