import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  history: []
};

export const respHistorySlice = createSlice({
  name: 'respHistory',
  initialState: initialState,
  reducers: {
    setHistory: (state, newState) => {
      state.history = [...state.history, newState.payload];
    },

    clearHistory: () => initialState,
  },
});

export const { setHistory, clearHistory } = respHistorySlice.actions;

export default respHistorySlice.reducer;