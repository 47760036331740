import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n

  .use(initReactI18next)
  // .use(I18nextBrowserLanguageDetector)
  // 實例化 initReactI18next
  .init({
    // 當目前的語言檔找不到對應的字詞時，會用 fallbackLng (en) 作為預設語言
    // fallbackLng: "en",
    // 預設語言
    lng: "en",
    resources: {
      en: {
        translation: require("./locales/en.json"),
      },
      tc_hk: {
        translation: require("./locales/tc_hk.json"),
      },
    },
    debug: false,
    interpolation: {
      // 是否要讓字詞 escaped 來防止 xss 攻擊，這裡因為 React.js 已經做了，就設成 false即可
      escapeValue: false,
    },
  });

export default i18n;
