import { useLocation } from "react-router-dom";
import { IonApp, setupIonicReact } from "@ionic/react";
import { Provider } from "react-redux";
import store from "./redux/store.js";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import AppRoute from "./AppRoute";
import { AppSettingsProvider } from "./providers/AppSettingsProvider";

import { useEffect } from "react";
import { ConfigProvider } from "antd";

setupIonicReact();

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function (e) {
        // console.log('change')
        window.history.pushState(null, null, document.URL);
      },
      false
    );
  }, []);

  useEffect(() => {
    window.history.pushState(null, null, document.URL);
  }, [location]);

  return (
    <Provider store={store}>
      <AppSettingsProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#600",
            },
          }}
        >
          <IonApp>
            <AppRoute />
          </IonApp>
        </ConfigProvider>
      </AppSettingsProvider>
    </Provider>
  );
};

export default App;
