import React from 'react'
import { IonButton, IonModal } from '@ionic/react'
import styles from './modal.module.scss';

const TickLocalModal = (props) => {
  const { animationType = 'none', visible = false, children, bgColor, showFooter } = props;

  return (
    <IonModal isOpen={visible} className={styles.localModal}>
      <>
        <div className={styles.localModalContainer} style={{background: bgColor ? bgColor : "#FFF", height: '100%'}}>
          {children}
        </div>
        {showFooter ? <div>{showFooter}</div> : null}
      </>
    </IonModal>
  );
}

export default TickLocalModal