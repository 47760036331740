import { MDS_ATTENDANCE, defaultSelectedAttendInfo } from "../config";
import CryptoJS from "crypto-js";

export const defineStatusCase = (arr, key) => {
  if (!arr || arr?.length === 0) return 0;
  if (!key) return 0;
  let counter = 0;
  arr.forEach((item) => {
    if (handleTextCase(item.packageStatus) !== key) {
      counter++;
    }
  });
  return counter;
};

export const handleConvertFromJson = (value, dispatch = () => {}) => {
  let parsed;
  value = value.split("\n").join("");
  value = value.split("\t").join("");
  try {
    parsed = JSON.parse(value);
    if (typeof parsed !== "object") {
      dispatch("Invalid QRCode value");
      return {
        error: true,
      };
    }
    return parsed;
  } catch (e) {
    dispatch("Invalid QRCode format");
    return {
      error: true,
    };
  }
};

export const reStructureResponse = (respData, setNoList) => {
  let newRespData = [];
  // let newNoList = [];
  if (!respData || respData.length === 0) return null;
  respData.forEach((item) => {
    const { transportationNoList, ...rest } = item;
    item?.transportationNoList?.forEach((no) => {
      // newNoList.push(no);
      newRespData.push({
        ...rest,
        transportationOrderNo: no,
        truckLoadingOrderNo: item.truckLoadingOrderNo,
      });
      // newRespData.push({[no]:{ ...rest, 'transportationOrderNo': no}})
    });
  });
  return newRespData;
};

//* Only the first letter of labels and words as a capital *//
export const handleTextCase = (words) => {
  if (!words) return words;
  return words.charAt(0).toUpperCase() + words.substring(1).toLowerCase();
};

export const enCrypt = (data) => {
  const cipherText = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    MDS_ATTENDANCE
  ).toString();
  return cipherText;
};

export const deCrypt = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, MDS_ATTENDANCE);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

export const handleSelectAttendInfo = (type, data) => {
  const selectedAttendInfo = deCrypt(
    localStorage.getItem("selectAttendInfo")
  );
  selectedAttendInfo[type] = data;
  localStorage.setItem("selectAttendInfo", enCrypt(selectedAttendInfo));
};
