import React, { createContext, useState, useEffect } from "react";
import { defaultSelectedAttendInfo, defaultSettings } from "../config";
import i18n from "../i18n";
import { languageSet } from "../config";
import { deCrypt, enCrypt } from "../helpers/helpers";

const AppSettingContext = createContext();

const AppSettingsProvider = (props) => {
  const [appSettings, setAppSettings] = useState(defaultSettings);

  useEffect(() => {
    checkSettingsAndSetSettings();
    checkSelectedAttendInfoAndSetSelectedAttendInfo();
  }, []);

  useEffect(() => {
    i18n.changeLanguage(languageSet[appSettings.language]);
  }, [appSettings]);

  const checkSettingsAndSetSettings = () => {
    let clientSettings = null;
    try {
      clientSettings = deCrypt(localStorage.getItem("clientSettings") || null);
      setAppSettings(clientSettings);
    } catch (e) {
      clientSettings = null;
    }
    if (!clientSettings) {
      localStorage.setItem("clientSettings", enCrypt(defaultSettings));
      setAppSettings(defaultSettings);
    }
  };

  const checkSelectedAttendInfoAndSetSelectedAttendInfo = () => {
    let selectedAttendInfo = null;
    try {
      selectedAttendInfo = deCrypt(localStorage.getItem("selectedAttendInfo"));
    } catch (e) {
      selectedAttendInfo = null;
    }
    if (!selectedAttendInfo) {
      localStorage.setItem(
        "selectAttendInfo",
        enCrypt(defaultSelectedAttendInfo)
      );
    }
  };

  return (
    <AppSettingContext.Provider
      value={{
        appSettings: appSettings,
        setAppSettings: setAppSettings,
      }}
    >
      {props.children}
    </AppSettingContext.Provider>
  );
};

export { AppSettingsProvider, AppSettingContext };
