import { MAG } from "../services";

// const prefix = "/event";
const prefix = "/api/mas/event";

export const fetchEventType = async () => {
  const endpoint = `${prefix}/type/listSelectOptions`;
  return await MAG.get(endpoint);
};

export const fetchEvent = async (id) => {
  const endpoint = `${prefix}/listSelectOptions/${id}`;
  return await MAG.get(endpoint);
};

export const eventAttendance = async (body) => {
  const endpoint = `${prefix}/attendance/save`;
  return await MAG.post(endpoint, { ...body });
};

// export const eventAttendance = async (body) => {
//   const endpoint = `${prefix}/attendance/save/${body.eventEnrollmentId}/${body.contactId}/${body.attendType}/${body.addBy}`;
//   return await MAG.get(endpoint);
// };

export const eventListByPage = async (body) => {
  const { page, pageSize, eventId } = body;
  const endpoint = `${prefix}/attendance/listByPage/${page}/${pageSize}?eventId=${eventId}`;
  return await MAG.get(endpoint);
};

export const eventDelete = async (id) => {
  const endpoint = `${prefix}/attendance/delete/${id}`;
  return await MAG.delete(endpoint);
};

export const eventMemberListByPage = async (body) => {
  let endpoint = `${prefix}/member/listByPage/${body.page}/${body.pageSize}?`;
  if (body.memberNos) {
    endpoint += `memberNos=${body.memberNos}&`;
  }
  if (body.eventId) {
    endpoint += `eventId=${body.eventId}&`;
  }
  return await MAG.get(endpoint);
};
