import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchEvent } from "../../services/apis/eventApis";
import { fetchEducationAllClassCode } from "../../services/apis/educationApis";
import { cpdActivityListSelectOption } from "../../services/apis/cpdApi";
import { Row, Select } from "antd";

const TickSelectWithSearch = (props) => {
  const {
    setFirstField = () => {},
    setSecondField = () => {},
    params = null,
    type,
    showId,
  } = props;
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    list(type);
  }, [params]);

  function list(type) {
    switch (type) {
      case "event":
        fetchEvent(params)
          .then((resp) => {
            let temp = [];
            if (Array.isArray(resp?.data?.data)) {
              resp?.data?.data.forEach((item) => {
                temp.push({
                  value: `${item?.eventTypeId}|${item?.id}`,
                  label: showId
                    ? `${item?.eventNameEn}(${item?.id})`
                    : item.eventNameEn,
                  remarks: `${item?.eventNameEn}${item?.id}`,
                });
              });
            }
            setOptions(temp);
          })
          .catch((e) => console.error(e));
        break;
      case "educationClass":
        fetchEducationAllClassCode()
          .then((resp) => {
            let temp = [];
            if (Array.isArray(resp?.data?.data)) {
              resp?.data?.data.forEach((item) => {
                temp.push({
                  value: `${item?.educationCourseTypeId}|${item?.id}`,
                  label: showId
                    ? `${item?.className}(${item?.classCode})`
                    : item?.className,
                  remarks: `${item?.className}${item.classCode}`,
                });
              });
              setOptions(temp);
            } else {
              const tempObj = {
                value: `${resp?.data?.data?.educationCourseTypeId}|${resp?.data?.data?.id}`,
                label: showId
                  ? `${resp?.data?.data?.className}(${resp?.data?.data?.classCode})`
                  : resp?.data?.data?.className,
                remarks: `${resp?.data?.data?.className}${resp?.data?.data?.classCode}`,
              };
              setOptions([tempObj]);
            }
          })
          .catch((e) => console.error(e));
        break;
      case "activityCode":
        cpdActivityListSelectOption(params)
          .then((resp) => {
            let temp = [];
            if (Array.isArray(resp?.data?.data)) {
              resp?.data?.data.forEach((item) => {
                temp.push({
                  value: `${item?.authorityBody}|${item?.id}`,
                  label: showId ? `${item?.title}(${item?.code})` : item?.title,
                  remarks: `${item?.title}${item?.code}`,
                });
              });
              setOptions(temp);
            } else {
              const tempObj = {
                value: `${resp?.data?.data?.authorityBody}|${resp?.data?.data?.id}`,
                label: showId
                  ? `${resp?.data?.data?.title}(${resp?.data?.data?.code})`
                  : resp?.data?.title,
                remarks: `${resp?.data?.data?.title}${resp?.data?.data?.code}`,
              };
              setOptions([tempObj]);
            }
          })
          .catch((e) => console.error(e));
        break;
      default:
    }
  }

  const onChange = (value) => {
    if (value) {
      const newValue = value.split("|");
      setFirstField(parseInt(newValue[0]));
      setSecondField(parseInt(newValue[1]));
    } else {
      setFirstField(null);
      setSecondField(null);
    }
  };

  return (
    <Row
      style={{
        padding: "8px 0px",
        borderBottom: "1px solid #DEDEDE",
        background: "white",
      }}
    >
      <Select
        showSearch
        allowClear
        style={{ width: "100%" }}
        placeholder={t("t_quickSearch")}
        options={options}
        filterOption={(input, option) =>
          option.remarks
            ? (option?.remarks).toLowerCase().includes(input.toLowerCase())
            : (option?.label).toLowerCase().includes(input.toLowerCase())
        }
        onChange={onChange}
      />
    </Row>
  );
};

export default TickSelectWithSearch;
