import React from 'react';
import { useDispatch } from 'react-redux';
import { setHistory } from '../redux/respHistorySlice';
import moment from 'moment';

const useDispatchRespHistory = () => {
  const dispatch = useDispatch();

  const dispatchRespHistory = (payload = null, resp = '', api = '') => {
    dispatch(setHistory(
      {
        payload,
        resp,
        time: moment().format('LLL'),
        api
      }
    ))
  }

  return dispatchRespHistory;
}

export default useDispatchRespHistory