import { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonButton,
} from "@ionic/react";
import ScanModal from "../../Modals/ScanModal/ScanModal";
import TickTitleBar from "../../components/TickTitleBar/TickTitleBar";
import { useHistory } from "react-router-dom";
import styles from "./Event.module.scss";
import { useTranslation } from "react-i18next";
import TickSelect from "../../components/TickSelect/TickSelect";
import TickSelectWithSearch from "../../components/TickSelectWithSearch/TickSelectWithSearch";
import { deCrypt, handleSelectAttendInfo } from "../../helpers/helpers";

const Event = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [eventType, setEventType] = useState(null);
  const [event, setEvent] = useState(null);

  const [isAttendanceModalVisible, setIsAttendanceModalVisible] =
    useState(false);
  const [isDepartureModalVisible, setIsDepartureModalVisible] = useState(false);

  useEffect(() => {
    deCryptStorage();
  }, []);

  const handleRefresh = (event) => {
    setTimeout(() => {
      clearState();
      event.detail.complete();
    }, 2000);
  };

  const handleAttendanceModalVisible = () => {
    setIsAttendanceModalVisible(!isAttendanceModalVisible);
  };

  const handleDepartureModalVisible = () => {
    setIsDepartureModalVisible(!isDepartureModalVisible);
  };

  const clearState = () => {
    setEventType(null);
    setEvent(null);
  };

  const handleEventType = (data) => {
    setEventType(data);
    handleSelectAttendInfo("eventType", data);
  };

  const handleEvent = (data) => {
    setEvent(data);
    handleSelectAttendInfo("event", data);
  };

  const deCryptStorage = () => {
    const eventRecord = deCrypt(localStorage.getItem("selectAttendInfo"));
    setEventType(parseInt(eventRecord["eventType"]));
    setEvent(parseInt(eventRecord["event"]));
  };

  return (
    <>
      <IonPage id="main-content">
        <IonHeader>
          <TickTitleBar
            title="t_event"
            onGoBack={() => history.push("/masAttendance/main")}
            rightBtnContent={event && "t_record"}
            onRightClick={() =>
              history.push(`/masAttendance/record?category=event&id=${event}`)
            }
          />
        </IonHeader>
        <IonContent className={styles.homeBg}>
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <div className={styles.mainContainer}>
            <div className={styles.upperWrapper}>
              <div>
                <TickSelectWithSearch
                  type="event"
                  setFirstField={setEventType}
                  setSecondField={setEvent}
                />
                <TickSelect
                  label={"t_eventType"}
                  setDetail={handleEventType}
                  detail={eventType}
                  type="eventType"
                />
                <TickSelect
                  label={"t_event"}
                  type="event"
                  setDetail={handleEvent}
                  detail={event}
                  params={eventType}
                  disabled={!eventType}
                />
              </div>
              <IonButton
                expand="block"
                className="manual"
                disabled={event ? false : true}
                onClick={() =>
                  history.push(
                    `/masAttendance/manualAttendance?category=event&id=${event}`
                  )
                }
              >
                {t("t_manual")}
              </IonButton>
            </div>
            <div className={styles.bottomWrapper}>
              <IonButton
                expand="block"
                className="attendance"
                disabled={event ? false : true}
                onClick={handleAttendanceModalVisible}
              >
                {t("t_attendance")}
              </IonButton>
              <IonButton
                expand="block"
                className="departure"
                onClick={handleDepartureModalVisible}
              >
                {t("t_departure")}
              </IonButton>
            </div>
          </div>
        </IonContent>
      </IonPage>
      {isAttendanceModalVisible ? (
        <ScanModal
          visible={isAttendanceModalVisible}
          setVisible={setIsAttendanceModalVisible}
          clearState={clearState}
          isAttendance
          id={event}
          type={"event"}
          details={[eventType, event]}
        />
      ) : null}
      {isDepartureModalVisible ? (
        <ScanModal
          visible={isDepartureModalVisible}
          setVisible={setIsDepartureModalVisible}
          clearState={clearState}
          id={event}
          type={"event"}
          details={[eventType, event]}
        />
      ) : null}
    </>
  );
};

export default Event;
