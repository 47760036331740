import { MAG } from "../services";

const prefix = "/api/mas/education";

export const fetchEducationCourseType = async () => {
  const endpoint = `${prefix}/courseType/listSelectOptions`;
  return await MAG.get(endpoint);
};

export const fetchEducationClass = async (id) => {
  const endpoint = `${prefix}/class/getByEducationCourseType/${id}`;
  return await MAG.get(endpoint);
};

export const fetchEducationAllClassCode = async () => {
  const endpoint = `${prefix}/class/listAllClassCode`;
  return await MAG.get(endpoint);
};

export const educationAttendance = async (body) => {
  const endpoint = `${prefix}/attendance/save`;
  return await MAG.post(endpoint, { ...body });
};

// export const educationAttendance = async (body) => {
//   const endpoint = `${prefix}/attendance/save/${body.educationClassEnrollmentId}/${body.contactId}/${body.attendType}/${body.addBy}`;
//   return await MAG.get(endpoint);
// };

export const educationListByPage = async (body) => {
  const { page, pageSize, educationClassId } = body;
  const endpoint = `${prefix}/attendance/listByPage/${page}/${pageSize}?educationClassId=${educationClassId}`;
  return await MAG.get(endpoint);
};

export const educationDelete = async (id) => {
  const endpoint = `${prefix}/attendance/delete/${id}`;
  return await MAG.delete(endpoint);
};

export const educationMemberListByPage = async (body) => {
  let endpoint = `${prefix}/member/listByPage/${body.page}/${body.pageSize}?`;
  if (body.memberNos) {
    endpoint += `memberNos=${body.memberNos}&`;
  }
  if (body.educationClassId) {
    endpoint += `educationClassId=${body.educationClassId}&`;
  }
  return await MAG.get(endpoint);
};

