import { serverTimeout } from "../config.js";
import { endpoint, APP } from "../env.js";

export const MAG = {
  async get(uri) {
    let response, text;
    let url = endpoint.baseURL + uri;
    try {
      response = await MAG.fetchWithTimeout(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": APP.auth.accessToken
        },
        credentials: "include",
      });

      text = await response.text();
      return {
        success: true,
        msg: "",
        data: JSON.parse(text),
      };
    } catch (e) {
      return {
        success: false,
        msg: "NETWORK_ERROR",
        data: e,
      };
    }
  },

  async post(uri, data) {
    let response, text;
    let url = endpoint.baseURL + uri;
    try {
      response = await MAG.fetchWithTimeout(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": APP.auth.accessToken
        },
        credentials: "include",
        body: JSON.stringify(data),
      });
      if (response.headers.get("Authorization")) {
        APP.auth.accessToken = response.headers.get("Authorization")
      }
      text = await response.text();
      return JSON.parse(text);
    } catch (e) {
      let timeoutMsg =
        e?.name == "AbortError"
          ? `LOADING TIMEOUT - ${serverTimeout / 100000} MIN`
          : "NETWORK_ERROR";
      console.log("MAG POST Catch Error::::", e);
      console.log("MAG POST Catch Endpoint::::", url);
      console.log("MAG POST Catch Request Data::::", data);
      console.log("MAG POST Catch Response Text::::", text);
      return {
        success: false,
        msg: timeoutMsg,
        data: [{ msg: timeoutMsg }],
      };
    }
  },

  async delete(uri) {
    let response, text;
    let url = endpoint.baseURL + uri;
    try {
      response = await MAG.fetchWithTimeout(url, {
        method: "Delete",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": APP.auth.accessToken
        },
        credentials: "include",
      });

      text = await response.text();
      return {
        success: true,
        msg: "",
        data: JSON.parse(text),
      };
    } catch (e) {
      return {
        success: false,
        msg: "NETWORK_ERROR",
        data: e,
      };
    }
  },


  async fetchWithTimeout(resource, options = {}) {
    const { timeout = serverTimeout } = options;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);
    return response;
  },
};
