import { IonPage, IonContent } from "@ionic/react";
import { APP } from "../../env";
import { useEffect } from "react";
import { useHistory } from "react-router";
import styles from "./Landing.module.scss";
import Logo from "../../assets/icons/Logo.svg";

const Landing = () => {
  const history = useHistory();

  useEffect(() => {
    restoreUserInfo();
  }, []);

  useEffect(() => {
    if (APP.auth.isAuth) {
      history.push("/masAttendance/main");
    } else {
      history.push("/masAttendance/login");
    }
  }, []);

  const restoreUserInfo = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo") || null);
    if (userInfo?.accessToken && userInfo?.isAuth && userInfo?.userId) {
      let authObj = {
        userId: userInfo.userId,
        accessToken: userInfo.accessToken,
        isAuth: true,
        userType: userInfo.userType,
        userName: userInfo.userName,
      };
      APP.auth = authObj;
    } else if (!userInfo && APP.auth.accessToken) {
      localStorage.setItem("userInfo", JSON.stringify(APP.auth));
    } else {
      localStorage.removeItem("userInfo");
      APP.auth = {
        userId: null,
        userType: null,
        accessToken: null,
        isAuth: false,
        userName: null,
      };
    }
  };

  return (
    <IonPage>
      <IonContent>
        <div className={styles.container}>
          <img src={Logo} alt="mds_icon" />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Landing;
