import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../theme/themeColor";

import useSize from "../../hooks/useSize";
import { deCrypt } from "../../helpers/helpers";

const RadioButton = (props) => {
  const {
    options = [],
    settingKey = "",
    setSettingValue = () => {},
    settingValue = {},
  } = props;
  // const [defaultKey, setDefaultKey] = useState(0);
  const { t } = useTranslation();

  const [ fontSize ] = useSize();

  useEffect(() => {
    getStorageKey();
  }, []);

  const getStorageKey = () => {
    let setting = null;
    try {
      setting =
        deCrypt(localStorage.getItem("clientSettings") || null);
        setSettingValue(setting)
    } catch (e) {
      console.log(e);
      setting = { ...settingValue, [settingKey]: 0 };
    }
  };

  return (
    <div>
      {options.map((item, index) => {
        let firstIndex = index == 0;
        let lastIndex = index == options.length - 1;
        return (
          <button
            key={`setting_button_${index}`}
            style={{
              ...inlineStyle.btn,
              borderRadius: firstIndex
                ? "6px 0px 0px 6px"
                : lastIndex
                ? "0px 6px 6px 0px"
                : "",
              backgroundColor:
                index == settingValue[settingKey] || 0
                  ? colors.themeRed
                  : colors.textGrey,
              ...fontSize(14)
            }}
            onClick={() => {
              setSettingValue({ ...settingValue, [settingKey]: index });
            }}
          >
            {t(item)}
          </button>
        );
      })}
    </div>
  );
};

export default RadioButton;

const inlineStyle = {
  btn: {
    padding: "8px 12px",
    background: colors.textGrey,
    color: "white",
  },
};
