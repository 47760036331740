import React from "react";
import { useSelector, useDispatch } from "react-redux";
import TickGlobalModal from "./TickGlobalModal";
import styles from "./modal.module.scss";
import warningIcon from "../../assets/icons/modalWarningIcon.svg";
import closeIcon from "../../assets/icons/titleBarIconCrossWhite.svg";
import { IonButton } from "@ionic/react";
import { closeModal } from "../../redux/tickModalSlice";

const TickWarningModal = () => {
  const dispatch = useDispatch();
  const tickGlobalModalState = useSelector((state) => state.tickModal);

  return (
    <TickGlobalModal autoClose={2000}>
      <div className={styles.warningContainer}>
        {tickGlobalModalState.params?.showCloseBtn && (
          <div
            className={styles.closeContainer}
            onClick={() => dispatch(closeModal())}
          >
            <img src={closeIcon} alt="close-icon" width={60} />
          </div>
        )}
        <img src={warningIcon} alt="warning-icon" className={styles.icon}/>
        {tickGlobalModalState.params.msg.map((message, index) => {
          return (
            <div className={styles.text} key={index}>
              {message?.msg}
            </div>
          );
        })}
        {tickGlobalModalState?.params?.btnText && (
          <IonButton
            className={styles.btn}
            onClick={() => {
              dispatch(closeModal());
              tickGlobalModalState?.params?.callback &&
                tickGlobalModalState?.params?.callback();
            }}
          >
            <div className={styles.btnText}>
              {tickGlobalModalState?.params?.btnText}
            </div>
          </IonButton>
        )}
      </div>
    </TickGlobalModal>
  );
};

export default TickWarningModal;
