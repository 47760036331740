import { configureStore } from '@reduxjs/toolkit';
import tickModalSlice from './tickModalSlice';
import authSlice from './authSlice';
import respHistorySlice from './respHistorySlice';

export default configureStore({
  reducer: {
    tickModal: tickModalSlice,
    authInfo: authSlice,
    respHistory: respHistorySlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['payload.callback'],
        ignorePaths: ['tickModal.params.callback'],
      },
    }),
});