export const DATE_FORMAT = "yyyy/MM/DD";
export const DATE_FORMATV2 = "DD/MM/YYYY";
export const maxLengthInput = 30;
export const serverTimeout = 200000;
export const MDS_ATTENDANCE = "mds attendance app";

export const defaultSettings = {
  language: 0,
  fontSize: 0,
};

export const defaultSelectedAttendInfo = {
  event: null,
  eventType: null,
  educationCourseType: null,
  educationClass: null,
  authorityBody: null,
  activityCode: null,
};

export const languageSet = ["en", "tc_hk"];

export const fontSizeKeySet = ["t_normal", "t_larger", "t_largest"];

export const fontSizeMultipleOfSet = [1, 1.5, 2];

export const widthMultipleOfSet = [1, 1.5, 1.9];

export const ionicCheckBoxEnum = ["15px", "20px", "25px"];

// export const path = "/"
